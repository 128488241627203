/* eslint-disable react/jsx-no-target-blank */
import { useFilter } from '../../FilterContext'
import { useParams } from 'react-router-dom'
import clsx from 'clsx'
import MultiSelection from '../../components/MultiSelection';
import SearchInputGroup from '../../components/SearchInputGroup';
import { useIntl } from 'react-intl';

interface SelectType {
  readonly value: string;
  readonly label: string;
}

export function ClientsFilter() {
  const params = useParams()
  const intl = useIntl()
  const { globalFilter, setGlobalFilter } = useFilter()

  const type: SelectType[] = [
    { value: 'SUB_MERCHANT', label: intl.formatMessage({ id: 'VERIFI.TYPE_OPTIONS.SUB_MERCHANT' }) },
    { value: 'DIRECT_MERCHANT', label: intl.formatMessage({ id: 'VERIFI.TYPE_OPTIONS.DIRECT_MERCHANT' }) },
  ];

  const status: SelectType[] = [
    { value: 'PENDING', label: intl.formatMessage({ id: `VERIFI.CLIENTS.STATUS_OPTIONS.PENDING` }) },
    { value: 'ACTIVE', label: intl.formatMessage({ id: `VERIFI.CLIENTS.STATUS_OPTIONS.ACTIVE` }) },
    { value: 'PROCESSING', label: intl.formatMessage({ id: `VERIFI.CLIENTS.STATUS_OPTIONS.PROCESSING` }) },
    { value: 'DELETE_PENDING', label: intl.formatMessage({ id: `VERIFI.CLIENTS.STATUS_OPTIONS.DELETE_PENDING` }) },
    { value: 'DELETE_PROCESSING', label: intl.formatMessage({ id: `VERIFI.CLIENTS.STATUS_OPTIONS.DELETE_PROCESSING` }) },
    { value: 'DELETED', label: intl.formatMessage({ id: `VERIFI.CLIENTS.STATUS_OPTIONS.DELETED` }) },
  ];

  return (
    <>
      <div className={(clsx(params.id ? 'blur' : ''))}>
        <div className='d-flex'>
          {/* Type */}
          <MultiSelection
            title='Type'
            globalFilterValue={globalFilter?.['rdrClientList']?.type?.values}
            options={type}
            onChange={(e) => {
              setGlobalFilter({ ...globalFilter, rdrClientList: { ...globalFilter.rdrClientList, type: { type: "in", values: e } } });
            }} />

          {/* Status */}
          <MultiSelection
            title='Status'
            globalFilterValue={globalFilter?.['rdrClientList']?.status?.values}
            options={status}
            hideOptions={
              status.filter((status) => status.value === 'PROCESSING' || status.value === 'DELETE_PROCESSING')
            }
            onChange={(e) => {
              const values = []
              e.forEach((option) => {
                values.push(option)
                if (option.value === 'PENDING') {
                  values.push(status.find((e) => e.value === 'PROCESSING'))
                } else if (option.value === 'DELETE_PENDING') {
                  values.push(status.find((e) => e.value === 'DELETE_PROCESSING'))
                }
              })
              setGlobalFilter({
                ...globalFilter,
                rdrClientList: { ...globalFilter.rdrClientList, status: { type: "in", values: values } },
              })
              sessionStorage.clear()
            }} />

          {/* Search */}
          <SearchInputGroup
            inputs={[ // key = filter key
              { key: 'name', title: 'Client Name' },
              { key: 'merchant_id', title: 'Client ID' },
            ]}
            section='rdrClientList' // globalFilter section key
            globalFilterValue={globalFilter}
            onSubmit={(searchValues) => setGlobalFilter({ ...globalFilter, rdrClientList: { ...globalFilter.rdrClientList, ...searchValues } })}
            onClear={(searchValues) => setGlobalFilter({ ...globalFilter, rdrClientList: { ...globalFilter.rdrClientList, ...searchValues } })}
          />
        </div>
      </div>
    </>
  )
}
