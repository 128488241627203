/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { useFormik } from 'formik'
import { useNavigate, useParams } from 'react-router-dom'
import { changePassword, checkResetToken } from '../core/_requests'
import { useIntl } from 'react-intl'


const initialValues = {
    password: '',
    cPassword: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function ResetPassword() {
    const intl = useIntl()
    const [loading, setLoading] = useState<boolean>(false)
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [isTokenExpire, setIsTokenExpire] = useState<boolean>(false);

    const navigate = useNavigate();

    const params = useParams();
    const token = params.token ?? '';

    useEffect(() => {
        (async () => {
            try {
                await checkResetToken(token);
            } catch (err: any) {
                setIsTokenExpire(true);
            }
        })();
    }, [])
    const resetPasswordSchema = Yup.object().shape({
        password: Yup.string()
            .min(8, intl.formatMessage({ id: 'VALIDATE_ERROR.MIN_8_CHAR' }))
            .max(50, intl.formatMessage({ id: 'VALIDATE_ERROR.MAX_50_CHAR' }))
            .matches(/^(?=.*[a-zA-Z])(?=.*\d)[^\s]{8,50}$/, intl.formatMessage({ id: 'VALIDATE_ERROR.PASSWORD_ALPHANUMERIC' }))
            .required(intl.formatMessage({ id: 'VALIDATE_ERROR.NEW_PASSWORD_REQUIRED' })),
        cPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], intl.formatMessage({ id: 'VALIDATE_ERROR.NOT_MATCH' }))
            .required(intl.formatMessage({ id: 'VALIDATE_ERROR.CONFIRM_PASSWORD_REQUIRED' })),
    })

    const formik = useFormik({
        initialValues,
        validationSchema: resetPasswordSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setLoading(true)
            try {
                const response = await changePassword(values.password, token); // api

                if (response.status === 200) {
                    setLoading(false)
                    setIsSuccess(true);
                    setTimeout(() => {
                        navigate('/auth')
                    }, 3000)
                }
            } catch (error) {
                setStatus(error.response.data.message)
                setSubmitting(false)
                setLoading(false)
            }
        },
    });
    return (
        <form
            className='form w-100'
            onSubmit={formik.handleSubmit}
            noValidate
            id='kt_login_signin_form'
        >
            {/* begin::Heading */}
            <div className='text-center mb-10'>
                <h1 className='text-dark mb-3'>
                    {intl.formatMessage({ id: 'TITLE.RESET_PASSWORD' })}</h1>
            </div>
            {/* begin::Heading */}

            {/* begin::Title */}
            {isSuccess && (
                <div className='mb-lg-15 alert alert-success'>
                    <div className='alert-text font-weight-bold'>
                        {intl.formatMessage({ id: 'RESET_PASSWORD.SUCCESS_TEXT' })}
                    </div>
                </div>
            )}
            {isTokenExpire && (
                <div className='mb-lg-15 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>
                        {intl.formatMessage({ id: 'RESET_PASSWORD.EXPIRED_TEXT' })}
                    </div>
                </div>
            )}

            {/* begin::Form group */}
            {!isTokenExpire && <div className='fv-row mb-10'>
                <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: 'RESET_PASSWORD.NEW_PASSWORD' })}</label>
                <input
                    type='password'
                    autoComplete='off'
                    {...formik.getFieldProps('password')}
                    className={clsx(
                        'form-control form-control-lg form-control-solid',
                    )}
                />
            </div>}
            {/* end::Form group */}

            {/* begin::Form group */}
            {!isTokenExpire && <div className='fv-row mb-10'>
                <div className='d-flex justify-content-between mt-n5'>
                    <div className='d-flex flex-stack mb-2'>
                        {/* begin::Label */}
                        <label className='form-label fw-bolder text-dark fs-6 mb-0'>{intl.formatMessage({ id: 'RESET_PASSWORD.CONFIRM_PASSWORD' })}</label>
                        {/* end::Label */}
                    </div>
                </div>
                <input
                    type='password'
                    autoComplete='off'
                    {...formik.getFieldProps('cPassword')}
                    className={clsx(
                        'form-control form-control-lg form-control-solid',
                    )}
                />
                {/* Error Message */}
                {formik.touched.password && formik.errors.password && (
                    <div className='fv-plugins-message-container text-danger'>
                        <span role='alert'>{formik.errors.password}</span>
                    </div>
                )}
                {formik.touched.cPassword && formik.errors.cPassword && (
                    <div className='fv-plugins-message-container text-danger'>
                        <span role='alert'>{formik.errors.cPassword}</span>
                    </div>
                )}
                {formik.status && (
                    <div className='fv-plugins-message-container text-danger'>
                        <span role='alert'>{formik.status}</span>
                    </div>
                )}
            </div>}
            {/* end::Form group */}

            {/* begin::Action */}
            {!isTokenExpire && <div className='text-center'>
                <button
                    type='submit'
                    id='kt_sign_in_submit'
                    className='btn btn-lg btn-primary w-100 mb-5'
                    disabled={formik.isSubmitting || !formik.isValid || isTokenExpire}
                >
                    {!loading && <span className='indicator-label'>{intl.formatMessage({ id: 'COMMON.CONTINUE' })}</span>}
                    {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                            {intl.formatMessage({ id: 'COMMON.PLEASE_WAIT' })}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}
                </button>
            </div>}

            {isTokenExpire && <div className='text-center'>
                <button className='btn btn-lg btn-primary w-100 mb-5' onClick={() => navigate('../')}>
                    <span className='indicator-label'>{intl.formatMessage({ id: 'COMMON.BACK' })}</span>
                </button>
            </div>}
            {/* end::Action */}
        </form>
    )
}
