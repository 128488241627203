/* eslint-disable jsx-a11y/anchor-is-valid */
import { Route, Link, Routes, Outlet } from 'react-router-dom'
import { Error500 } from './components/Error500'
import { Error404 } from './components/Error404'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { useIntl } from 'react-intl'
import { useAuth } from '../auth'

const ErrorsLayout = () => {
  const intl = useIntl()
  
  // Icon will be navigate to alert list if no permission
  // const { currentUser, logout } = useAuth()
  // const statisticPermission = currentUser?.permissions?.find((permissions) => permissions?.service === 'STATISTICS');
  // const haveDashBoardPermission = statisticPermission?.access?.some((access) => access === 'dashboard.read') ?? false;

  return (
    <div className='d-flex flex-column flex-root'>
      <div
        className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
        style={{ backgroundImage: `url('${toAbsoluteUrl('/media/illustrations/progress-hd.png')}')` }}
      >
        <div className='d-flex flex-column flex-column-fluid text-center p-10 py-lg-20 justify-content-center mb-15'>
          <a href='/alerts' className='mt-10 mb-10 pt-lg-20'>
            <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo.png')} className='h-50px mb-5' />
          </a>
          <div className='pt-lg-10 mb-10'>
            <Outlet />
            <div className='text-center'>
              {/* <Link to={haveDashBoardPermission ? '/dashboard' : '/ethoca/alerts/list'} className='btn btn-lg btn-primary fw-bolder'> */}
              <Link to='/ethoca/alerts/list' className='btn btn-lg btn-primary fw-bolder'>
                {intl.formatMessage({ id: 'MESSAGE.GO_TO_HOMEPAGE' })}
              </Link>
            </div>
          </div>
        </div>

        <div className='d-flex flex-center flex-column-auto p-10'>
          <div className='d-flex align-items-center fw-bold fs-6'>

          </div>
        </div>
      </div>
    </div>
  )
}

const ErrorsPage = () => (
  <Routes>
    <Route element={<ErrorsLayout />}>
      <Route path='404' element={<Error404 />} />
      <Route path='500' element={<Error500 />} />
      <Route index element={<Error404 />} />
    </Route>
  </Routes>
)

export { ErrorsPage }
