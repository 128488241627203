import React, { FormEvent, useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../../../../_metronic/helpers/AssetHelpers';
import { useIntl } from 'react-intl';

type Inputs = {
  key: string;
  title: string;
  minWidth?: number;
};

type SearchValues = {
  [key: string]: { type: 'contains'; value: string } | undefined;
};

type Props = {
  inputs: Inputs[];
  section: string;
  globalFilterValue: Record<string, any>;
  onSubmit: (searchValues: SearchValues) => void;
  onClear: (searchValues: SearchValues) => void;
  dependency?: string | undefined;
};

const SearchInputGroup = ({
  inputs,
  section,
  globalFilterValue,
  onSubmit,
  onClear,
  dependency,
}: Props) => {
  const [searchValues, setSearchValues] = useState<Record<string, string>>({});
  const intl = useIntl();
  useEffect(
    () => {
      if (globalFilterValue[section]) {
        const globalFilterSearchingKeys = Object.keys(globalFilterValue[section]).filter((key) =>
          inputs.map((input) => input.key).includes(key)
        );
        const globalFilterSearchingValues = globalFilterSearchingKeys.reduce(
          (accumulator, currentValue) => {
            return {
              ...accumulator,
              [currentValue]:
                globalFilterValue[section][currentValue] !== undefined
                  ? globalFilterValue[section][currentValue].value
                  : undefined,
            };
          },
          {}
        );
        setSearchValues(globalFilterSearchingValues);
      }
    },
    dependency ? [dependency] : []
  );

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formattedSearchValue: SearchValues | {} = {};
    for (let key in searchValues) {
      if (searchValues[key] === '') {
        formattedSearchValue[key] = undefined;
      } else {
        formattedSearchValue[key] = { type: 'contains', value: searchValues[key] };
      }
    }
    onSubmit(formattedSearchValue);
  };

  const handleClear = (key) => {
    setSearchValues({ ...searchValues, [key]: '' });
    onClear({ [key]: undefined });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className='pb-2'>
        {/* Content */}
        <div className='menu-item d-inline-flex flex-wrap'>
          {inputs.map((input) => (
            <div className='pt-1 me-7 ' key={input.key}>
              <span className='text-dark text-uppercase fs-8 ls-1'>{input.title}</span>
              <div className='pt-1 pb-0 d-flex'>
                {/* Input */}
                <input
                  type='text'
                  className='form-control h-40px px-2 fw-normal fs-7 text-gray-800'
                  name={input.key}
                  value={searchValues?.[input.key] ?? ''}
                  style={{ height: '40.42px', minWidth: input?.minWidth ?? '280px' }}
                  onChange={(e) =>
                    setSearchValues({ ...searchValues, [input.key]: e.target.value })
                  }
                />
                {/* Clear */}
                {searchValues?.[input.key] !== '' && searchValues?.[input.key] !== undefined && (
                  <SVG
                    onClick={() => handleClear(input.key)}
                    className='position-relative aside-input ms-n6'
                    src={toAbsoluteUrl('/media/svg/general/cross.svg')}
                  />
                )}
              </div>
            </div>
          ))}

          {/* Search */}
          <div className='py-0'>
            <button
              className='btn btn-secondary px-2 py-1 mt-7 fs-6'
              style={{ height: '40.42px', minWidth: '140px' }}
              type='submit'
            >
              {intl.formatMessage({ id: 'FILTER.SEARCH' })}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default SearchInputGroup;
