import { useState } from 'react';
import DatePicker from 'react-datepicker';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers/AssetHelpers';
import clsx from 'clsx';

type Props = {
  onChange: (value: { start: Date; end: Date } | undefined) => void;
  defaultValue?: {
    startDate: Date | null;
    endDate: Date | null;
  };
  label?: string;
  placeholder?: string;
  className?: string;
};

export default function DatePickerFilter({
  onChange,
  defaultValue = { startDate: null, endDate: null },
  label = 'Date Picker',
  placeholder,
  className,
}: Props) {
  const [dateRange, setDateRange] = useState([defaultValue.startDate, defaultValue.endDate]);
  const [startDate, endDate] = dateRange;

  function handleClear(): void {
    setDateRange([defaultValue.startDate, defaultValue.endDate]);
    onChange(
      defaultValue.startDate !== null
        ? {
            start: defaultValue.startDate,
            end: defaultValue.endDate,
          }
        : undefined
    );
  }

  function handleChange([start, end]: Date[]): void {
    setDateRange([start, end]);
    if (start !== null && end !== null) {
      onChange({
        start,
        end,
      });
    }
  }

  return (
    <div className={clsx(className)}>
      {/* Header */}
      <div className='menu-content'>
        <div className='menu-content py-1'>
          <span className='menu-section text-dark text-uppercase fs-8 ls-1'>{label}</span>
        </div>
      </div>
      {/* Content */}
      <div className='menu-content'>
        <div className='menu-content py-0 d-flex'>
          <DatePicker
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            className='select-react px-2'
            onChange={handleChange}
            placeholderText={placeholder}
            calendarClassName='center-header-arrows'
          />
          {startDate === null && endDate === null ? (
            // down arrow
            <SVG
              className='position-relative select-react-dropdown-button ms-n5'
              src={toAbsoluteUrl('/media/svg/general/down-arrow.svg')}
            />
          ) : (
            // clear button
            <SVG
              onClick={handleClear}
              className='position-relative select-react-clear-button ms-n5'
              src={toAbsoluteUrl('/media/svg/general/cross.svg')}
            />
          )}
        </div>
      </div>
    </div>
  );
}
