import axios from 'axios'
import { PasswordChangeTokenModal, TokenModel, UserModel } from './_models'

const API_URL = process.env.REACT_APP_CBP_SERVICE_BASE_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}users/current`
export const LOGIN_URL = `${API_URL}auth/login`
export const REGISTER_URL = `${API_URL}auth/register`

export const PASSWORD_RESET_URL = `${API_URL}auth/password-reset`
export const CHECK_PASSWORD_RESET_TOKEN_URL = `${API_URL}auth/tokens/current`

export const CHANGE_PASSWORD_TOKEN_URL = `${API_URL}auth/tokens/password`
export const CHANGE_PASSWORD_URL = `${API_URL}users/current/password`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post(LOGIN_URL, {
    name: email,
    password,
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

export function requestPasswordReset(email: string) {
  return axios.post(PASSWORD_RESET_URL, {
    email,
  })
}

export function getUserByToken() {
  return axios.get(GET_USER_BY_ACCESSTOKEN_URL, {
  })
}

export function getPasswordChangeToken(currentPassword: string) {
  return axios.post(CHANGE_PASSWORD_TOKEN_URL, {
    password: currentPassword,
  })
}

export function changePassword(password: string, token: string) {
  let reqInstance = axios.create({
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
  reqInstance.defaults.headers.put['Content-Type'] = 'application/json';
  return reqInstance.put(CHANGE_PASSWORD_URL, JSON.stringify(password));
}

export function checkResetToken(token: string) {
  let reqInstance = axios.create({
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
  return reqInstance.head(CHECK_PASSWORD_RESET_TOKEN_URL);
}
