import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';

export type Props = {
    onChange: (v: boolean) => void;
}

export default function Captcha({ onChange }: Props) {
    const intl = useIntl()
    const [isCorrect, setIsCorrect] = useState<boolean>(false);

    useEffect(() => {
        loadCaptchaEnginge(6, 'white', 'black');
    }, [])

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        if (validateCaptcha(e.currentTarget.value, false)) {
            setIsCorrect(true);
            onChange(true);
        } else {
            setIsCorrect(false);
            onChange(false);
        };
    }

    return (
        <div className='fv-row mb-5 w-100'>
            <div className='pb-3'>
                <label className='form-label fw-bolder text-gray-900 fs-6'>{intl.formatMessage({ id: 'COMMON.CAPTCHA' })}</label>
                <div className='d-flex'>
                    <input className={clsx("form-control form-control-lg form-control-solid", {
                        'is-valid': isCorrect,
                    })} onChange={handleChange}></input>
                    <div className='pt-3'>
                        <LoadCanvasTemplateNoReload />
                    </div>
                </div>
            </div>
        </div>
    )
}