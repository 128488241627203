// import React from 'react'
import {PageTitleHeader} from './PageTitleHeader'

export function MenuInner() {
  return (
    <>
      <PageTitleHeader />
    </>
  )
}
