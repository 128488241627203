// import React from 'react'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import InfoTooltip from '../../../../app/modules/components/tooltip/InfoTooltip'

export function PageTitleHeader() {
  const intl = useIntl()
  const location = useLocation()
  const path = location.pathname
    .split('/')
    .filter((path) => path !== '')
    .map((path) => path.replaceAll('-', '_'))
  const secondLastPath = path[path.length - 2] || path[path.length - 1]

  return (
    <>
      <div className='mt-7 display-6 d-flex'>
        <span>{intl.formatMessage({ id: 'MENU.' + secondLastPath?.toUpperCase() })}</span>
        {secondLastPath === 'enquiries' &&
          <div className='mx-3 d-flex align-items-center'>
            <InfoTooltip id='number_of_descriptor_tooltip' placement='bottom'>
              <p className='text-start m-0'>
                {intl.formatMessage({ id: 'ALERTS.ENQUIRIES.HEADER_1' })}
                <br />
                {intl.formatMessage({ id: 'ALERTS.ENQUIRIES.HEADER_2' })}
              </p>
            </InfoTooltip>
          </div>}
      </div>
    </>
  )
}