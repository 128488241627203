import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers/AssetHelpers';
import { useFilter } from '../../FilterContext';
import { endOfDay } from 'date-fns';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useIntl } from 'react-intl';
import { zonedTimeToUtc } from 'date-fns-tz';

type Props = {
    globalFilterValue: any;
    onChange: (value: any) => void;
};

const DatePickerFilter = ({ globalFilterValue, onChange }: Props) => {
    const intl = useIntl();
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;

    function offsetHongkongTimeZoneDate(date: Date): Date {
        date.setMinutes(date.getMinutes() + (480 + date.getTimezoneOffset()))
        return date;
    }

    useEffect(() => {
        if (globalFilterValue?.filterValue === 'datePicker') {
            setDateRange([offsetHongkongTimeZoneDate(new Date(globalFilterValue.from)), offsetHongkongTimeZoneDate(new Date(globalFilterValue.to))]);
        } else {
            setDateRange([null, null]);
        }
    }, [globalFilterValue])

    function handleClear(): void {
        setDateRange([null, null]);
        onChange({
            "alert.AlertTimestamp": {
                "type": "range",
                "from": new Date((new Date().getTime() - Number((1000 * 3600 * 24)))), // default 24 hrs
                "filterValue": "last24Hours"
            },
        });
    }

    function handleChange([start, end]: Date[]): void {
        setDateRange([start, end]);
        if (start !== null && end !== null) {
            onChange({
                'alert.AlertTimestamp': {
                    "type": "range",
                    "from": zonedTimeToUtc(new Date(start), 'Hongkong').toISOString(),
                    "to": zonedTimeToUtc(new Date(endOfDay(end)), 'Hongkong').toISOString(),
                    "filterValue": "datePicker",
                }
            });
        }
    }

    return (
        <div className="me-7">
            {/* Header */}
            <div className='menu-content'>
                <div className='menu-content py-1'>
                    <span className='menu-section text-dark text-uppercase fs-8 ls-1'>{intl.formatMessage({ id: 'ALERTS.DATE_PICKER' })}</span>
                </div>
            </div>
            {/* Content */}
            <div className='menu-content'>
                <div className='menu-content py-0 d-flex'>
                    <DatePicker
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={handleChange}
                        className='select-react'
                        calendarClassName='center-header-arrows'
                    />
                    {startDate === null && startDate === null ?
                        // down arrow
                        <SVG className='position-relative select-react-dropdown-button ms-n6' src={toAbsoluteUrl('/media/svg/general/down-arrow.svg')} /> :
                        // clear button
                        <SVG onClick={handleClear} className='position-relative select-react-clear-button ms-n6' src={toAbsoluteUrl('/media/svg/general/cross.svg')} />}
                </div>
            </div>
        </div>
    );
};

export default DatePickerFilter;