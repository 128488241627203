/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect } from 'react';
import { useFilter } from '../../FilterContext'
import { useParams } from 'react-router-dom'
import clsx from 'clsx'
import DateRangeSelect from '../components/DateRangeSelect';
import SearchInputGroup from '../../components/SearchInputGroup';
import MultiSelection from '../../components/MultiSelection';
import { useIntl } from 'react-intl';
import DatePickerFilter from '../components/DatePickerFilter';
import SingleSelection from '../../components/SingleSelection';

interface SelectType {
  readonly value: string;
  readonly label: string;
}


export function EnquiriesFilter() {
  //
  const params = useParams()
  const { globalFilter, setGlobalFilter } = useFilter()
  const intl = useIntl()

  const status: SelectType[] = [
    { value: 'PENDING', label: intl.formatMessage({ id: 'ALERTS.ENQUIRIES.STATUS_OPTIONS.PENDING' }) },
    { value: 'PROCESSING', label: intl.formatMessage({ id: 'ALERTS.ENQUIRIES.STATUS_OPTIONS.PROCESSING' }) },
    { value: 'COMPLETED', label: intl.formatMessage({ id: 'ALERTS.ENQUIRIES.STATUS_OPTIONS.COMPLETED' }) },
  ];

  return (
    <>
      <div className={(clsx(params.id ? 'blur' : ''))}>
        <div className='d-flex'>
          {/* Status */}
          <MultiSelection
            title={intl.formatMessage({ id: 'ALERTS.STATUS' })}
            globalFilterValue={globalFilter?.['ethocaEnquiriesList']?.status?.values}
            options={status}
            hideOptions={
              status.filter((status) => status.value === 'PROCESSING')
            }
            onChange={(e) => {
              const values = []
              e.forEach((option) => {
                values.push(option)
                if (option.value === 'PENDING') {
                  values.push(status.find((e) => e.value === 'PROCESSING'))
                } 
              })
              setGlobalFilter({ ...globalFilter, ethocaEnquiriesList: { ...globalFilter.ethocaEnquiriesList, status: { type: "in", values: values } } });
            }} />

          {/* Search */}
          <SearchInputGroup
            inputs={[ // key = filter key
              { key: 'arn', title: intl.formatMessage({ id: 'ALERTS.ARN' }), minWidth: 230 },
              { key: 'descriptor', title: intl.formatMessage({ id: 'ALERTS.DESCRIPTOR' }), minWidth: 230 },
              { key: 'ethoca.alert', title: intl.formatMessage({ id: 'ALERTS.MATCHED_ETHOCA_ID' }), minWidth: 230 },
            ]}
            section='ethocaEnquiriesList' // globalFilter section key
            globalFilterValue={globalFilter}
            onSubmit={(searchValues) => setGlobalFilter({ ...globalFilter, ethocaEnquiriesList: { ...globalFilter.ethocaEnquiriesList, ...searchValues } })}
            onClear={(searchValues) => setGlobalFilter({ ...globalFilter, ethocaEnquiriesList: { ...globalFilter.ethocaEnquiriesList, ...searchValues } })}
          />
        </div>
      </div>
    </>
  )
}
