/* eslint-disable react/jsx-no-target-blank */
import { useFilter } from '../../FilterContext'
import { useParams } from 'react-router-dom'
import clsx from 'clsx'
import MultiSelection from '../../components/MultiSelection';
import SearchInputGroup from '../../components/SearchInputGroup';
import { useIntl } from 'react-intl';

interface SelectType {
  readonly value: string;
  readonly label: string;
}

export function CaidsFilter() {
  const params = useParams()
  const intl = useIntl()
  const { globalFilter, setGlobalFilter } = useFilter()

  const status: SelectType[] = [
    { value: 'PENDING', label: intl.formatMessage({ id: 'CAIDS.STATUS_OPTIONS.PENDING' }) }, // ["PENDING", "PROCESSING", "ADD_PENDING_PROCESSING"]
    { value: 'PROCESSING', label: intl.formatMessage({ id: 'CAIDS.STATUS_OPTIONS.PROCESSING' }) },
    { value: 'ADD_PENDING_PROCESSING', label: intl.formatMessage({ id: 'CAIDS.STATUS_OPTIONS.ADD_PENDING_PROCESSING' }) },
    { value: 'DELETE_PENDING', label: intl.formatMessage({ id: 'CAIDS.STATUS_OPTIONS.DELETE_PENDING' }) }, // ["DELETE_PENDING", "DELETE_PROCESSING"]
    { value: 'DELETE_PROCESSING', label: intl.formatMessage({ id: 'CAIDS.STATUS_OPTIONS.DELETE_PROCESSING' }) },
    { value: 'ACTIVE', label: intl.formatMessage({ id: 'CAIDS.STATUS_OPTIONS.ACTIVE' }) },
    { value: 'DELETED', label: intl.formatMessage({ id: 'CAIDS.STATUS_OPTIONS.DELETED' }) },
    { value: 'REJECTED', label: intl.formatMessage({ id: 'CAIDS.STATUS_OPTIONS.REJECTED' }) },
  ];

  return (
    <>
      <div className={(clsx(params.id ? 'blur' : ''))}>
        <div className='d-flex'>
          {/* Status */}
          <MultiSelection
            title={intl.formatMessage({ id: 'CAIDS.STATUS' })}
            globalFilterValue={globalFilter?.['rdrCaidList']?.status?.values}
            options={status}
            hideOptions={
              status.filter((status) => status.value === 'PROCESSING' || status.value === 'ADD_PENDING_PROCESSING' || status.value === 'DELETE_PROCESSING')
            }
            onChange={(e) => {
              const values = []
              e.forEach((option) => {
                values.push(option)
                if (option.value === 'PENDING') {
                  values.push(status.find((e) => e.value === 'PROCESSING'))
                  values.push(status.find((e) => e.value === 'ADD_PENDING_PROCESSING'))
                } else if (option.value === 'DELETE_PENDING') {
                  values.push(status.find((e) => e.value === 'DELETE_PROCESSING'))
                }
              })
              setGlobalFilter({ ...globalFilter, rdrCaidList: { ...globalFilter.rdrCaidList, status: { type: "in", values: values } } });
            }} />
        </div>

        {/* Search */}
        <SearchInputGroup
          inputs={[ // key = filter key
            { key: 'merchant_id', title: 'Client ID' },
            { key: 'dba', title: 'DBA' },
            { key: 'bin', title: 'BIN' },
            { key: 'caid', title: 'CAID' },
          ]}
          section='rdrCaidList' // globalFilter section key
          globalFilterValue={globalFilter}
          onSubmit={(searchValues) => setGlobalFilter({ ...globalFilter, rdrCaidList: { ...globalFilter.rdrCaidList, ...searchValues } })}
          onClear={(searchValues) => setGlobalFilter({ ...globalFilter, rdrCaidList: { ...globalFilter.rdrCaidList, ...searchValues } })}
        />
      </div>
    </>
  )
}
