import { format } from 'date-fns';
import { XMLParser } from 'fast-xml-parser';
import type { DateRange } from '../../app/modules/components/filter/dashboard/DatePickerFilter';

export const toAbsoluteUrl = (pathname: string) => process.env.PUBLIC_URL + pathname;

export const valueHandler = (value: string | string[] | undefined) => {
  if (value === undefined || value === null) {
    return '—';
  }
  if (Array.isArray(value) && value.length === 0) {
    return '—';
  }
  if (value === '') {
    return '—';
  }

  return value;
};

export const xmlToJson = (xml) => {
  const xmlParser = new XMLParser();
  const options = {
    attributeNamePrefix: '',
    attrNodeName: '_attributes', //default is 'false'
    textNodeName: '_text',
    ignoreAttributes: false,
    ignoreNameSpace: false,
    allowBooleanAttributes: false,
    parseNodeValue: false,
    parseAttributeValue: false,
    parseTrueNumberOnly: true,
    trimValues: false,
    decodeHTMLchar: false,
    cdataTagName: '_cdata', //default is 'false'
    cdataPositionChar: '\\c',
  };

  return xmlParser.parse(xml, options);
};

export const randomAlphanum = (length: number) => {
  const alphanums = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  return Array.from({ length }, () => alphanums[Math.floor(Math.random() * alphanums.length)]).join(
    ''
  );
};

export const responseToJson = async (response: Response) => {
  if (!response.ok) throw new Error('Network response was not ok');

  const contentType = response.headers.get('content-type');
  if (contentType && contentType.includes('application/xml')) {
    return xmlToJson(await response.text());
  } else {
    return await response.json();
  }
};

export const toTitleCase = (str: string, enable: boolean = true) => {
  if (enable) {
    return str.replace(
      /\w\S*/g,
      (text) => text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()
    )
  } else {
    return str
  }
};

export const formatDateRangeToISODate = (date: DateRange) => {
  return {
    from: format(date.from, 'yyyy-MM-dd'),
    to: format(date.to, 'yyyy-MM-dd'),
  };
};

export const creditCardType = (cc: string) => {
  let amex = new RegExp('^3[47][0-9]{13}$');
  let visa = new RegExp('^4[0-9]{12}(?:[0-9]{3})?$');
  let cup1 = new RegExp('^62[0-9]{14}[0-9]*$');
  let cup2 = new RegExp('^81[0-9]{14}[0-9]*$');

  let mastercard = new RegExp('^5[1-5][0-9]{14}$');
  let mastercard2 = new RegExp('^2[2-7][0-9]{14}$');

  let disco1 = new RegExp('^6011[0-9]{12}[0-9]*$');
  let disco2 = new RegExp('^62[24568][0-9]{13}[0-9]*$');
  let disco3 = new RegExp('^6[45][0-9]{14}[0-9]*$');

  let diners = new RegExp('^3[0689][0-9]{12}[0-9]*$');
  let jcb = new RegExp('^35[0-9]{14}[0-9]*$');


  if (visa.test(cc)) {
      return 'VISA';
  }
  if (amex.test(cc)) {
      return 'AMEX';
  }
  if (mastercard.test(cc) || mastercard2.test(cc)) {
      return 'MASTERCARD';
  }
  if (disco1.test(cc) || disco2.test(cc) || disco3.test(cc)) {
      return 'DISCOVER';
  }
  if (diners.test(cc)) {
      return 'DINERS';
  }
  if (jcb.test(cc)) {
      return 'JCB';
  }
  if (cup1.test(cc) || cup2.test(cc)) {
      return 'CHINA_UNION_PAY';
  }
  return undefined;
}