import React, { useContext, useState } from 'react'

const DatatableContext = React.createContext<{
  datatableContext: Record<string, any>
  setDatatableContext: React.Dispatch<React.SetStateAction<Record<string, any>>>
}>({
  datatableContext: undefined,
  setDatatableContext: function (value: React.SetStateAction<Record<string, any>>): void { },
})

// export DatatableContext value
export function useDatatableContext() {
  return useContext(DatatableContext)
}

export function DatatableContextProvider({ children }) {
  const [datatableContext, setDatatableContext] = useState<Record<string, any>>([])

  return (
    <DatatableContext.Provider value={{ datatableContext, setDatatableContext }}>
      {children}
    </DatatableContext.Provider>
  )
}
