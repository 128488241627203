import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  Dispatch,
  SetStateAction,
  useRef,
} from 'react'
import { LayoutSplashScreen } from '../../../../_metronic/layout/core'
import { AuthModel, UserModel } from './_models'
import * as authHelper from './AuthHelpers'
import { getUserByToken } from './_requests'
import { useFilter } from '../../components/filter/FilterContext'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
  refresh: () => Promise<string | null>
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => { },
  currentUser: undefined,
  setCurrentUser: () => { },
  logout: () => { },
  refresh() { return Promise.resolve(null); },
}

const baseUrl = process.env.REACT_APP_CBP_SERVICE_BASE_URL as string;

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC = ({ children }) => {
  const { setGlobalFilter } = useFilter();
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    setGlobalFilter({}); // clear global filter
    saveAuth(undefined)
    setCurrentUser(undefined)
  }

  const refresh = async () => {
    const response = await fetch(`${baseUrl}auth/tokens/root`, {
      method: 'POST',
      headers: {
        "Authorization": "Bearer " + auth.refreshToken
      }
    });
    if (!response.ok) {
      saveAuth(undefined);
      setCurrentUser(undefined);
      return null;
    }

    const result = await response.json();

    localStorage.setItem('accessToken', result.results.access);
    localStorage.setItem('refreshToken', result.results.root);

    saveAuth({
      accessToken: result.results.access,
      refreshToken: result.results.root,
    });

    return result.results.access;
  };

  return (
    <AuthContext.Provider value={{ auth, saveAuth, currentUser, setCurrentUser, logout, refresh }}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC = ({ children }) => {
  const { auth, logout, setCurrentUser } = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          const { data } = await getUserByToken()
          if (data) {
            setCurrentUser(data.results)
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth.accessToken) {
      requestUser()
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export { AuthProvider, AuthInit, useAuth }
