import React, { useEffect } from 'react';
import Select from 'react-select';
import { useFilter } from '../../FilterContext';
import { useIntl } from 'react-intl';
import { zonedTimeToUtc } from 'date-fns-tz';

type Props = {
    globalFilterValue: any;
    onChange: (value: any) => void;
};

const DateRangeSelect = ({ globalFilterValue, onChange }: Props) => {
    const { globalFilter, setGlobalFilter } = useFilter();
    const intl = useIntl()

    // set default value
    useEffect(() => {
        if (globalFilter.alerts?.['alert.AlertTimestamp']?.filterValue !== undefined && typeof globalFilter.alerts?.['alert.AlertTimestamp']?.filterValue === 'number') {
            // Yesterday | Last 7 days | Last 15 days | Last 30 days | Last 60 days
            setGlobalFilter({
                ...globalFilter,
                alerts: {
                    ...globalFilter.alerts,
                    "alert.AlertTimestamp": {
                        "type": "range",
                        "from": globalFilter.alerts['alert.AlertTimestamp'].from,
                        "to": globalFilter.alerts['alert.AlertTimestamp'].to,
                        "filterValue": globalFilter.alerts['alert.AlertTimestamp'].filterValue
                    },
                }
            })
        } else if (globalFilter.alerts?.['alert.AlertTimestamp']?.filterValue !== undefined && typeof globalFilter.alerts?.['alert.AlertTimestamp']?.filterValue === 'string') {
            // Last 24 Hours
            if (globalFilter.alerts?.['alert.AlertTimestamp']?.filterValue === 'Last24Hours') {
                setGlobalFilter({
                    ...globalFilter,
                    alerts: {
                        ...globalFilter.alerts,
                        "alert.AlertTimestamp": {
                            "type": "range",
                            "from": globalFilter.alerts['alert.AlertTimestamp'].from,
                            "filterValue": globalFilter.alerts['alert.AlertTimestamp'].filterValue
                        },
                    }
                })
            } else {
                // Last Month | Current Month
                setGlobalFilter({
                    ...globalFilter,
                    alerts: {
                        ...globalFilter.alerts,
                        "alert.AlertTimestamp": {
                            "type": "range",
                            "from": globalFilter.alerts['alert.AlertTimestamp'].from,
                            "to": globalFilter.alerts['alert.AlertTimestamp'].to,
                            "filterValue": globalFilter.alerts['alert.AlertTimestamp'].filterValue
                        },
                    }
                })
            }
        } else {
            // default Last 24 Hours
            setGlobalFilter({
                ...globalFilter,
                alerts: {
                    ...globalFilter.alerts,
                    "alert.AlertTimestamp": {
                        "type": "range",
                        "from": new Date((new Date().getTime() - Number((1000 * 3600 * 24)))), // default 24 hrs
                        "filterValue": "last24Hours"
                    },
                }
            })
        }
    }, []);

    const AlertDateRange = [
        { value: "last24Hours", label: intl.formatMessage({ id: 'FILTER.DATE_SELECT.LAST_24_HOURS' }) },
        { value: 1000 * 3600 * 24 * 1, label: intl.formatMessage({ id: 'FILTER.DATE_SELECT.LAST_1_DAY' }) },
        { value: 1000 * 3600 * 24 * 7, label: intl.formatMessage({ id: 'FILTER.DATE_SELECT.LAST_7_DAY' }) },
        { value: 1000 * 3600 * 24 * 15, label: intl.formatMessage({ id: 'FILTER.DATE_SELECT.LAST_15_DAY' }) },
        { value: 1000 * 3600 * 24 * 30, label: intl.formatMessage({ id: 'FILTER.DATE_SELECT.LAST_30_DAY' }) },
        { value: 1000 * 3600 * 24 * 60, label: intl.formatMessage({ id: 'FILTER.DATE_SELECT.LAST_60_DAY' }) },
        { value: "lastMonth", label: intl.formatMessage({ id: 'FILTER.DATE_SELECT.LAST_MONTH' }) },
        { value: "currentMonth", label: intl.formatMessage({ id: 'FILTER.DATE_SELECT.CURRENT_MONTH' }) },
    ];

    const handleChange = ({ value, label }) => {
        if (value === "currentMonth") {
            const currentDate = new Date();
            const firstDayOfCurrentMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
            const nextMonthFirstDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
            const lastDayOfCurrentMonth = new Date(nextMonthFirstDay.getTime() - 1);
            onChange({
                'alert.AlertTimestamp': {
                    "type": "range",
                    "from": zonedTimeToUtc(firstDayOfCurrentMonth, 'Hongkong').toISOString(),
                    "to": zonedTimeToUtc(lastDayOfCurrentMonth, 'Hongkong').toISOString(),
                    "filterValue": "currentMonth",
                }
            });
        } else if (value === "lastMonth") {
            const currentDate = new Date();
            const firstDayOfLastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
            const currentMonthFirstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
            const lastDayOfLastMonth = new Date(currentMonthFirstDay.getTime() - 1);
            onChange({
                'alert.AlertTimestamp': {
                    "type": "range",
                    "from": zonedTimeToUtc(firstDayOfLastMonth, 'Hongkong').toISOString(),
                    "to": zonedTimeToUtc(lastDayOfLastMonth, 'Hongkong').toISOString(),
                    "filterValue": "lastMonth",
                }
            });
        } else if (value === "last24Hours") {
            const yesterday = new Date((new Date().getTime() - Number((1000 * 3600 * 24))));
            onChange({
                'alert.AlertTimestamp': {
                    "type": "range",
                    "from": yesterday,
                    "filterValue": "last24Hours",
                }
            });
        } else {
            // Yesterday | Last 7 days | Last 15 days | Last 30 days | Last 60 days
            const currentDate = new Date();
            const today = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())
            const yesterday = zonedTimeToUtc(new Date(today.getTime() - 1), 'Hongkong').toISOString();
            const selectedDay = zonedTimeToUtc(new Date((today.getTime() - Number(value))), 'Hongkong').toISOString();
            onChange({
                'alert.AlertTimestamp': {
                    "type": "range",
                    "from": selectedDay,
                    "to": yesterday,
                    "filterValue": Number(value),
                }
            });
        }
    };

    return (
        <div className="me-7">
            {/* Header */}
            <div className='menu-content'>
                <div className='menu-content py-1'>
                    <span className='menu-section text-dark text-uppercase fs-8 ls-1'>{intl.formatMessage({ id: 'FILTER.ALERT_TIME' })}</span>
                </div>
            </div>
            {/* Content */}
            <div className='menu-content'>
                <div className='menu-content py-0 min-w-150px'>
                    <Select
                        placeholder={intl.formatMessage({ id: "COMMON.SELECT" })}
                        name='dateRange'
                        id='dateRange'
                        className='border border-gray-100'
                        options={AlertDateRange}
                        value={AlertDateRange.find((option) => option.value === globalFilterValue?.filterValue) ?? null}
                        onChange={handleChange}
                    />
                </div>
            </div>
        </div>
    );
};

export default DateRangeSelect;