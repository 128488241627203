import { FC } from 'react'
import { useLang } from './Metronici18n'
import { IntlProvider } from 'react-intl'
import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/locale-data/en'
import flatten from 'flat'

import enMessages from './messages/en.json'
import tcMessages from './messages/tc.json'
import scMessages from './messages/sc.json'

const allMessages = {
  en: enMessages,
  sc: scMessages,
  tc: tcMessages,
}

const I18nProvider: FC = ({ children }) => {
  const locale = useLang()
  const messages = allMessages[locale]

  return (
    <IntlProvider locale={locale} messages={flatten(messages)}>
      {children}
    </IntlProvider>
  )
}

export { I18nProvider }
