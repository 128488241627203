/* eslint-disable react/jsx-no-target-blank */
import {useParams} from 'react-router-dom'
import {ListFilter} from './ListFilter'
import {TreeFilter} from './TreeFilter'

export function ClientsFilter() {
  const params = useParams()

  return (
    <>
      {params['*'].split('/')[2] === 'list' && <ListFilter />}
      {params['*'].split('/')[2] === 'tree' && <TreeFilter />}
    </>
  )
}
