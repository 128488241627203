import { useEffect } from 'react'
import { AuthModel } from './_models'
import { Service } from '../../../pages/users/detail/sections/Permission'
import { useNavigate } from 'react-router-dom'

const AUTH_LOCAL_STORAGE_KEY = 'pcg-user-token'
const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: { headers: { Authorization: string } }) => {
      const auth = getAuth()
      if (auth && auth.accessToken) {
        config.headers.Authorization = `Bearer ${auth.accessToken}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )
}

export function CheckUserRegisteredService(user: any, service: Service) {
  const navigate = useNavigate();
  const isUserRegistered = user.registered_service.includes(service);
  const isUserIncludeServiceMerchantPermission = user.permissions.some((permission) => permission?.service === service && permission?.scope?.type === 'merchants');
  const isUserIncludeServiceAllPermission = user.permissions.some((permission) => permission?.service === service && permission?.scope?.type === 'all');
  
  useEffect(() => {
    if (!isUserRegistered || (!isUserIncludeServiceMerchantPermission && !isUserIncludeServiceAllPermission)) {
      navigate('/contact-us')
    }
  }, [user])
}

export { getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY }