import { useParams } from 'react-router-dom';
import { useFilter } from '../../FilterContext';
import clsx from 'clsx';
import SearchInputGroup from '../../components/SearchInputGroup';
import DatePickerFilter from '../../beceipt/components/DatePickerFilter';
import { zonedTimeToUtc } from 'date-fns-tz';
import { endOfDay } from 'date-fns';
import SingleSelection from '../../components/SingleSelection';

export function CasesFilter() {
  const params = useParams();
  const { globalFilter, setGlobalFilter } = useFilter();

  return (
    <>
      <div className={clsx(params.id ? 'blur' : '')}>
        {/* Search */}
        <SearchInputGroup
          inputs={[
            { key: 'arn', title: 'ARN', minWidth: 240 },
            { key: 'client_name', title: 'Client Name', minWidth: 240 },
            { key: 'dba', title: 'DBA', minWidth: 120 },
            { key: 'bin', title: 'BIN', minWidth: 120 },
            { key: 'caid', title: 'CAID', minWidth: 120 },
            { key: 'case_id', title: 'Austreme ID', minWidth: 130 },
          ]}
          section='rdrCasesList'
          globalFilterValue={globalFilter}
          onSubmit={(searchValues) =>
            setGlobalFilter({
              ...globalFilter,
              rdrCasesList: { ...globalFilter.rdrCasesList, ...searchValues },
            })
          }
          onClear={(searchValues) =>
            setGlobalFilter({
              ...globalFilter,
              rdrCasesList: { ...globalFilter.rdrCasesList, ...searchValues },
            })
          }
        />

        <div className='d-flex'>
          {/* Date Picker */}
          <DatePickerFilter
            label='Alert Time'
            onChange={(selectedDate) =>
              setGlobalFilter({
                ...globalFilter,
                rdrCasesList: {
                  ...globalFilter.rdrCasesList,
                  case_date: selectedDate && {
                    from: zonedTimeToUtc(new Date(selectedDate.start), 'Hongkong').toISOString(),
                    to: zonedTimeToUtc(
                      new Date(endOfDay(selectedDate.end)),
                      'Hongkong'
                    ).toISOString(),
                  },
                },
              })
            }
            placeholder='Filter Alert Time...'
            className='me-6'
          />

          {/* Outcome */}
          <SingleSelection
            title='Outcome'
            globalFilterValue={globalFilter?.rdrCasesList?.['outcome']?.values}
            options={[
              { value: 'Accepted', label: 'Accepted' },
              { value: 'Declined', label: 'Declined' },
            ]}
            isClearable
            onChange={(e) => {
              setGlobalFilter({
                ...globalFilter,
                rdrCasesList: {
                  ...globalFilter.rdrCasesList,
                  outcome: e?.value ? { type: 'in', values: [e] } : undefined,
                },
              });
            }}
          />
        </div>
      </div>
    </>
  );
}
