/* eslint-disable react/jsx-no-target-blank */
import { useFilter } from '../../FilterContext'
import { useParams } from 'react-router-dom'
import clsx from 'clsx'
import MultiSelection from '../../components/MultiSelection';
import SearchInputGroup from '../../components/SearchInputGroup';
import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { api } from '../../../../api/api';
import { useAuth } from '../../../../auth';
import Select from 'react-select';

interface DescriptorType {
  readonly value: string;
  readonly label: string;
}
interface DescriptorStatus {
  readonly value: string;
  readonly label: string;
}
interface AutoAddStatus {
  readonly value: boolean;
  readonly label: string;
}
export function DescriptorsFilter() {
  const { auth, refresh } = useAuth();
  const params = useParams()
  const intl = useIntl()
  const { globalFilter, setGlobalFilter } = useFilter()
  const [clientListOptions, setClientListOptions] = useState([]);
  const [input, setInput] = useState('');
  const [save, setSave] = useState('');
  const [clientIdValue, setClientIdValue] = useState(undefined)

  const DescriptorType: DescriptorType[] = [
    { value: 'STARTS_WITH', label: intl.formatMessage({ id: 'DESCRIPTORS.TYPE_OPTIONS.STARTS_WITH' }) },
    { value: 'EXACT_MATCH', label: intl.formatMessage({ id: 'DESCRIPTORS.TYPE_OPTIONS.EXACT_MATCH' }) },
  ];

  const DescriptorStatus: DescriptorStatus[] = [
    { value: 'PENDING', label: intl.formatMessage({ id: 'DESCRIPTORS.STATUS_OPTIONS.PENDING' }) }, // ["PENDING", "PROCESSING", "ADD_PENDING_PROCESSING"]
    { value: 'PROCESSING', label: intl.formatMessage({ id: 'DESCRIPTORS.STATUS_OPTIONS.PROCESSING' }) },
    { value: 'ADD_PENDING_PROCESSING', label: intl.formatMessage({ id: 'DESCRIPTORS.STATUS_OPTIONS.ADD_PENDING_PROCESSING' }) },
    { value: 'DELETE_PENDING', label: intl.formatMessage({ id: 'DESCRIPTORS.STATUS_OPTIONS.DELETE_PENDING' }) }, // ["DELETE_PENDING", "DELETE_PROCESSING"]
    { value: 'DELETE_PROCESSING', label: intl.formatMessage({ id: 'DESCRIPTORS.STATUS_OPTIONS.DELETE_PROCESSING' }) },
    { value: 'ACTIVE', label: intl.formatMessage({ id: 'DESCRIPTORS.STATUS_OPTIONS.ACTIVE' }) },
    { value: 'DELETED', label: intl.formatMessage({ id: 'DESCRIPTORS.STATUS_OPTIONS.DELETED' }) },
    { value: 'REJECTED', label: intl.formatMessage({ id: 'DESCRIPTORS.STATUS_OPTIONS.REJECTED' }) },
  ];

  const AutoAddStatus: AutoAddStatus[] = [
    { value: true, label: intl.formatMessage({ id: 'DESCRIPTORS.AUTO_ADD' }) },
    { value: false, label: intl.formatMessage({ id: 'DESCRIPTORS.PORTAL_ADD' }) },
  ]

  const { data, status } = useQuery(['merchantList'], async () => {
    const result = await api(`merchants/list`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        "filters": {
          type:
            { type: 'in', values: ['STANDALONE_MERCHANT', 'SUB_MERCHANT', 'DIRECT_MERCHANT'] },
          status: {
            type: 'in',
            values: ["ACTIVE"],
          },
        },
        "sort": [{ field: "merchant_id", order: 1 }],
        "limit": 0,
        "offset": 0,
      }),
    }, { token: auth?.accessToken, refresh }).then((resp) => resp?.json());
    return result;
  })

  // get merchant id list options for react select
  useEffect(() => {
    if (status === 'success' && data.data !== undefined) {
      const clientOptions = data.data.length > 0 ?
        new Set(data.data.map(
          (clientDetail) => clientDetail.merchant_id)) : new Set();

      setClientListOptions(Array.from(clientOptions).map(
        (merchantId: string) => { return { value: { search: false, id: merchantId }, label: merchantId } }));
    };
  }, [data, status])

  return (
    <>
      <div>
        <div className='d-flex'>
          {/* Type */}
          <MultiSelection
            title={intl.formatMessage({ id: 'DESCRIPTORS.TYPE' })}
            globalFilterValue={globalFilter?.['descriptors']?.type?.values}
            options={DescriptorType}
            onChange={(e) => {
              setGlobalFilter({ ...globalFilter, descriptors: { ...globalFilter.descriptors, type: { type: "in", values: e } } });
            }} />

          {/* Status */}
          <MultiSelection
            title={intl.formatMessage({ id: 'DESCRIPTORS.STATUS' })}
            globalFilterValue={globalFilter?.['descriptors']?.status?.values}
            options={DescriptorStatus}
            hideOptions={
              DescriptorStatus.filter((status) => status.value === 'PROCESSING' || status.value === 'ADD_PENDING_PROCESSING' || status.value === 'DELETE_PROCESSING')
            }
            onChange={(e) => {
              const values = []
              e.forEach((option) => {
                values.push(option)
                if (option.value === 'PENDING') {
                  values.push(DescriptorStatus.find((e) => e.value === 'PROCESSING'))
                  values.push(DescriptorStatus.find((e) => e.value === 'ADD_PENDING_PROCESSING'))
                } else if (option.value === 'DELETE_PENDING') {
                  values.push(DescriptorStatus.find((e) => e.value === 'DELETE_PROCESSING'))
                }
              })
              setGlobalFilter({ ...globalFilter, descriptors: { ...globalFilter.descriptors, status: { type: "in", values: values } } });
            }} />

          {/* Add From */}
          <MultiSelection
            title={intl.formatMessage({ id: 'DESCRIPTORS.ADD_FROM' })}
            globalFilterValue={globalFilter?.['descriptors']?.auto_add?.values}
            options={AutoAddStatus}
            onChange={(e) => {
              setGlobalFilter({ ...globalFilter, descriptors: { ...globalFilter.descriptors, auto_add: { type: "in", values: e } } });
            }} />

          {/* Client ID */}
          <div className='me-7'>
            {/* Header */}
            <div className='menu-content'>
              <div className='menu-content py-1'>
                <span className='menu-section text-dark text-uppercase fs-8 ls-1'>
                  {intl.formatMessage({ id: 'DESCRIPTORS.CLIENT_ID' })}
                </span>
              </div>
            </div>
            {/* Content */}
            <div className='menu-content py-0 min-w-200px'>
              <Select
                options={
                  (input !== '' && input !== undefined)
                    ? [
                      {
                        value: { search: true, id: input },
                        label: `Search "${input}"`,
                      },
                      ...clientListOptions,
                    ] : clientListOptions
                }
                defaultValue={{
                  value: { search: false, id: globalFilter?.['descriptors']?.merchant_id?.value ?? '' },
                  label: globalFilter?.['descriptors']?.merchant_id?.value ?? '',
                }}
                inputValue={input}
                onInputChange={(e) => {
                  setInput(e)
                }}
                value={{ value: { search: true, id: clientIdValue ?? globalFilter?.['descriptors']?.merchant_id?.value }, label: clientIdValue ?? globalFilter?.['descriptors']?.merchant_id?.value }}
                onChange={(option: any, action) => {
                  setGlobalFilter({ ...globalFilter, descriptors: { ...globalFilter.descriptors, merchant_id: { type: "contains", value: option?.value?.id ?? '' } } });
                  setSave(option?.value?.id);
                  setClientIdValue(option?.value?.id)
                }}
                onFocus={() => {
                  setInput(save);
                }}
                blurInputOnSelect={true}
                isClearable={true}
              />
            </div>
          </div>

          {/* Search */}
          <SearchInputGroup
            inputs={[ // key = filter key
              { key: 'criterion', title: intl.formatMessage({ id: 'DESCRIPTORS.DESCRIPTOR' }) },
            ]}
            section='descriptors' // globalFilter section key
            globalFilterValue={globalFilter}
            onSubmit={(searchValues) => setGlobalFilter({ ...globalFilter, descriptors: { ...globalFilter.descriptors, ...searchValues } })}
            onClear={(searchValues) => setGlobalFilter({ ...globalFilter, descriptors: { ...globalFilter.descriptors, ...searchValues } })}
          />
        </div>
      </div>
    </>
  )
}
