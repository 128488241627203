import clsx from 'clsx';
import { endOfDay } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { useParams } from 'react-router-dom';
import { useFilter } from '../../FilterContext';
import SearchInputGroup from '../../components/SearchInputGroup';
import SingleSelection from '../../components/SingleSelection';
import DatePickerFilter from '../components/DatePickerFilter';

export function TransactionsFilter() {
  const params = useParams();
  const { globalFilter, setGlobalFilter } = useFilter();

  return (
    <>
      <div className={clsx(params.id ? 'blur' : '')}>
        {/* Search */}
        <SearchInputGroup
          inputs={[
            { key: 'iCS_id', title: 'ID', minWidth: 240 },
            { key: 'transaction.order_reference', title: 'Order Reference', minWidth: 240 },
            { key: 'transaction.transaction_id', title: 'Transaction ID', minWidth: 240 },
            { key: 'transaction.mid', title: 'MID', minWidth: 220 },
            // { key: 'merchant_name', title: 'Merchant Name', minWidth: 200 },
          ]}
          section='transactions'
          globalFilterValue={globalFilter}
          onSubmit={(searchValues) =>
            setGlobalFilter({
              ...globalFilter,
              transactions: { ...globalFilter.transactions, ...searchValues },
            })
          }
          onClear={(searchValues) =>
            setGlobalFilter({
              ...globalFilter,
              transactions: { ...globalFilter.transactions, ...searchValues },
            })
          }
        />

        <div className='hstack justify-content-between align-items-end'>
          {/* Select */}
          <div className='d-flex'>
            {/* Date Picker */}
            <DatePickerFilter
              onChange={(selectedDate) =>
                setGlobalFilter({
                  ...globalFilter,
                  transactions: {
                    ...globalFilter.transactions,
                    created_at: selectedDate && {
                      from: zonedTimeToUtc(new Date(selectedDate.start), 'Hongkong').toISOString(),
                      to: zonedTimeToUtc(
                        new Date(endOfDay(selectedDate.end)),
                        'Hongkong'
                      ).toISOString(),
                    },
                  },
                })
              }
              placeholder='Filter Received At...'
              className='me-6'
            />

            {/* Issue */}
            <SingleSelection
              title='Issue'
              globalFilterValue={globalFilter?.transactions?.['status.buyer_report_issue']?.values}
              options={[
                { value: 'Y', label: 'Yes' },
                { value: 'N', label: 'No' },
              ]}
              isClearable
              onChange={(e) => {
                setGlobalFilter({
                  ...globalFilter,
                  transactions: {
                    ...globalFilter.transactions,
                    'status.buyer_report_issue': e?.value ? { type: 'in', values: [e] } : undefined,
                  },
                });
              }}
            />

            {/* Buyer Request */}
            <SingleSelection
              title='Buyer Request'
              globalFilterValue={globalFilter?.transactions?.['refund.request']?.values}
              options={[
                { value: 'FULL_REFUND', label: 'Full Refund' },
                { value: 'PARTIAL_REFUND', label: 'Partial Refund' },
                { value: 'EXCHANGE', label: 'Exchange' },
                { value: 'NOT_REFUND', label: 'Not Refund' },
                { value: 'OTHERS', label: 'Others' },
                { value: '', label: '—' },
              ]}
              isClearable
              onChange={(e) => {
                setGlobalFilter({
                  ...globalFilter,
                  transactions: {
                    ...globalFilter.transactions,
                    'refund.request':
                      typeof e?.value === 'string' ? { type: 'in', values: [e] } : undefined,
                  },
                });
              }}
            />

            {/* Refund Status */}
            <SingleSelection
              title='Refund Status'
              globalFilterValue={globalFilter?.transactions?.['refund.status']?.values}
              options={[
                { value: 'FULL_REFUND', label: 'Full Refund' },
                { value: 'PARTIAL_REFUND', label: 'Partial Refund' },
                { value: 'EXCHANGE', label: 'Exchange' },
                { value: 'NOT_REFUND', label: 'Not Refund' },
                { value: 'OTHERS', label: 'Others' },
                { value: '', label: '—' },
              ]}
              isClearable
              onChange={(e) => {
                setGlobalFilter({
                  ...globalFilter,
                  transactions: {
                    ...globalFilter.transactions,
                    'refund.status':
                      typeof e?.value === 'string' ? { type: 'in', values: [e] } : undefined,
                  },
                });
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
