/* eslint-disable react/jsx-no-target-blank */
import { useEffect } from 'react'
import { useFilter } from '../../FilterContext';
import InputSearch from '../../components/InputSearch';
import Select from 'react-select';
import clsx from 'clsx';
import MultiSelection from '../../components/MultiSelection';
import SearchInputGroup from '../../components/SearchInputGroup';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
interface ClientStatus {
    readonly value: string;
    readonly label: string;
}
export function TreeFilter() {
    const params = useParams();
    const { globalFilter, setGlobalFilter } = useFilter();
    const intl = useIntl()
    const ClientStatus: ClientStatus[] = [
        { value: 'PENDING', label: intl.formatMessage({ id: 'CLIENTS.STATUS_OPTIONS.PENDING' }) },
        { value: 'PROCESSING', label: intl.formatMessage({ id: 'CLIENTS.STATUS_OPTIONS.PROCESSING' }) },
        { value: 'ACTIVE', label: intl.formatMessage({ id: 'CLIENTS.STATUS_OPTIONS.ACTIVE' }) },
        { value: 'DELETED', label: intl.formatMessage({ id: 'CLIENTS.STATUS_OPTIONS.DELETED' }) },
        { value: 'REJECTED', label: intl.formatMessage({ id: 'CLIENTS.STATUS_OPTIONS.REJECTED' }) },
    ];
    useEffect(() => {
        setGlobalFilter({
            "Clients": {
                "status": {
                    type: "contains",
                    values: ["All"]
                },
            },

            ...globalFilter,
        })
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <div>
                <div className="d-flex">
                    {/* Status */}
                    <MultiSelection
                        title={intl.formatMessage({ id: 'CLIENTS.STATUS' })}
                        globalFilterValue={globalFilter?.['clientTree']?.status?.values}
                        options={ClientStatus}
                        onChange={(e) => {
                            setGlobalFilter({
                                ...globalFilter,
                                clientTree: { ...globalFilter.clientTree, status: { type: "in", values: e } },
                            })
                            sessionStorage.clear()
                        }} />

                    {/* Search */}
                    <SearchInputGroup
                        inputs={[ // key = filter key
                            { key: 'name', title: intl.formatMessage({ id: 'CLIENTS.CLIENT_NAME' }) },
                            { key: 'merchant_id', title: intl.formatMessage({ id: 'CLIENTS.CLIENT_ID' }) },
                        ]}
                        section='clientTree' // globalFilter section key
                        globalFilterValue={globalFilter}
                        onSubmit={(searchValues) => setGlobalFilter({ ...globalFilter, clientTree: { ...globalFilter.clientTree, ...searchValues } })}
                        onClear={(searchValues) => setGlobalFilter({ ...globalFilter, clientTree: { ...globalFilter.clientTree, ...searchValues } })}
                    />
                </div >
            </div>
        </>
    )
}