import clsx from 'clsx';
import { PropsWithChildren } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import type { Placement } from 'react-bootstrap/esm/types';

type Props = {
  id: string;
  placement?: Placement;
  iconFontSize?: 'fs-1' | 'fs-2' | 'fs-3' | 'fs-4' | 'fs-5';
  className?: string;
};

export default function InfoTooltip({
  id,
  placement = 'top',
  iconFontSize = 'fs-3',
  className,
  children,
}: PropsWithChildren<Props>) {
  return (
    <OverlayTrigger key={id} placement={placement} overlay={<Tooltip id={id}>{children}</Tooltip>}>
      <i className={clsx('fa fa-info-circle text-gray-400', iconFontSize, className)}></i>
    </OverlayTrigger>
  );
}
