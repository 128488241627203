import React, { useContext, useEffect, useState } from 'react';
import ToastMessage, { ToastStyle } from './ToastMessage';

const ToastContext = React.createContext<{
    toast: { show: boolean, type: ToastStyle, header: string, message: string },
    setToast: React.Dispatch<React.SetStateAction<{ show: boolean, type: ToastStyle, header: string, message: string }>>
}>({
    toast: { show: false, type: '', header: '', message: '' },
    setToast: (function (value: React.SetStateAction<{ show: boolean, type: ToastStyle, header: string, message: string }>): void { })
});

// export ToastContext value
export function useToast() {
    return useContext(ToastContext);
}

export function ToastProvider({ children }) {
    const [toast, setToast] = useState<{ show: boolean, type: ToastStyle, header: string, message: string }>({ show: false, type: '', header: '', message: '' })
    
    return (
        <ToastContext.Provider value={{ toast, setToast }}>
            {children}
            <ToastMessage toast={toast} setToast={setToast} />
        </ToastContext.Provider>
    )
}