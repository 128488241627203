/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect } from 'react';
import { useFilter } from '../../FilterContext'
import { useParams } from 'react-router-dom'
import clsx from 'clsx'
import DateRangeSelect from '../components/DateRangeSelect';
import SearchInputGroup from '../../components/SearchInputGroup';
import MultiSelection from '../../components/MultiSelection';
import { useIntl } from 'react-intl';
import DatePickerFilter from '../components/DatePickerFilter';
import SingleSelection from '../../components/SingleSelection';

export function AlertsFilter() {
  //
  const params = useParams()
  const { globalFilter, setGlobalFilter } = useFilter()
  const intl = useIntl()



  interface AlertType {
    readonly value: string;
    readonly label: string;
  }
  interface AlertOutcome {
    readonly value: any;
    readonly label: string;
  }
  interface AlertInitiatedBy {
    readonly value: string;
    readonly label: string;
  }
  interface AlertStatus {
    readonly value: string;
    readonly label: string;
  }

  const AlertType: AlertType[] = [
    { value: 'ConfirmedFraudAlerts', label: intl.formatMessage({ id: 'ALERTS.ALERT_TYPE_OPTIONS.CONFIRM_FRAUD' }) },
    { value: 'CustomerDisputeAlerts', label: intl.formatMessage({ id: 'ALERTS.ALERT_TYPE_OPTIONS.CUSTOMER_DISPUTE' }) },
  ];


  const AlertOutcome: AlertOutcome[] = [
    { value: 'stopped', label: intl.formatMessage({ id: 'ALERTS.OUTCOME_SHORT_OPTIONS.STOPPED' }) },
    { value: 'partially_stopped', label: intl.formatMessage({ id: 'ALERTS.OUTCOME_SHORT_OPTIONS.PARTIALLY_STOPPED' }) },
    { value: 'previously_cancelled', label: intl.formatMessage({ id: 'ALERTS.OUTCOME_SHORT_OPTIONS.PREVIOUSLY_CANCELLED' }) },
    { value: 'missed', label: intl.formatMessage({ id: 'ALERTS.OUTCOME_SHORT_OPTIONS.MISSED' }) },
    { value: 'shipper_contacted', label: intl.formatMessage({ id: 'ALERTS.OUTCOME_SHORT_OPTIONS.SHIPPER_CONTACTED' }) },
    { value: 'account_suspended', label: intl.formatMessage({ id: 'ALERTS.OUTCOME_SHORT_OPTIONS.ACCOUNT_SUSPENDED' }) },
    { value: 'in_progress', label: intl.formatMessage({ id: 'ALERTS.OUTCOME_SHORT_OPTIONS.IN_PROGRESS' }) },
    { value: 'resolved', label: intl.formatMessage({ id: 'ALERTS.OUTCOME_SHORT_OPTIONS.RESOLVED' }) },
    { value: 'previously_refunded', label: intl.formatMessage({ id: 'ALERTS.OUTCOME_SHORT_OPTIONS.PREVIOUSLY_REFUNDED' }) },
    { value: 'unresolved_dispute', label: intl.formatMessage({ id: 'ALERTS.OUTCOME_SHORT_OPTIONS.UNRESOLVED_DISPUTE' }) },
    { value: 'notfound', label: intl.formatMessage({ id: 'ALERTS.OUTCOME_SHORT_OPTIONS.NOTFOUND' }) },
    { value: 'other', label: intl.formatMessage({ id: 'ALERTS.OUTCOME_SHORT_OPTIONS.OTHER' }) },
  ];


  const AlertInitiatedBy: AlertInitiatedBy[] = [
    { value: 'issuer', label: intl.formatMessage({ id: 'ALERTS.INITIATED_BY_OPTIONS.ISSUER' }), },
    { value: 'cardholder', label: intl.formatMessage({ id: 'ALERTS.INITIATED_BY_OPTIONS.CARDHOLDER' }) },
    { value: 'not_available', label: intl.formatMessage({ id: 'ALERTS.INITIATED_BY_OPTIONS.UNKNOWN' }) },
  ];

  const AlertStatus: AlertStatus[] = [
    { value: "PENDING", label: intl.formatMessage({ id: 'ALERTS.STATUS_OPTIONS.PENDING' }) },
    { value: "COMPLETED", label: intl.formatMessage({ id: 'ALERTS.STATUS_OPTIONS.COMPLETED' }) },
    { value: "ERROR", label: intl.formatMessage({ id: 'ALERTS.STATUS_OPTIONS.ERROR' }) },
    { value: "PROCESSING", label: intl.formatMessage({ id: 'ALERTS.STATUS_OPTIONS.PROCESSING' }) },
    { value: "FAILED", label: intl.formatMessage({ id: 'ALERTS.STATUS_OPTIONS.FAILED' }) }
  ]

  return (
    <>
      <div>
        <div className='d-flex'>
          {/* Date Range */}
          <DateRangeSelect
            globalFilterValue={globalFilter.alerts?.['alert.AlertTimestamp']}
            onChange={(selectedDayRange) => setGlobalFilter({ ...globalFilter, alerts: { ...globalFilter.alerts, ...selectedDayRange } })} />

          {/* Date Picker */}
          <DatePickerFilter globalFilterValue={globalFilter.alerts?.['alert.AlertTimestamp']} onChange={(selectedDate) => setGlobalFilter({ ...globalFilter, alerts: { ...globalFilter.alerts, ...selectedDate } })} />

          {/* Alert Type */}
          <MultiSelection
            title={intl.formatMessage({ id: 'ALERTS.ALERT_TYPE' })}
            globalFilterValue={globalFilter?.['alerts']?.type?.values}
            options={AlertType}
            onChange={(e) => {
              setGlobalFilter({ ...globalFilter, alerts: { ...globalFilter.alerts, type: { type: "in", values: e } } });
            }} />

          {/* Outcome */}
          <MultiSelection
            title={intl.formatMessage({ id: 'ALERTS.OUTCOME' })}
            globalFilterValue={globalFilter?.['alerts']?.outcome?.values}
            options={[
              { value: true, label: intl.formatMessage({ id: 'ALERTS.OUTCOME_SHORT_OPTIONS.ALL_PROVIDED' }) },
              { value: null, label: intl.formatMessage({ id: 'ALERTS.OUTCOME_SHORT_OPTIONS.NOT_PROVIDED' }) },
              ...AlertOutcome
            ]}
            onChange={(e) => {
              if (e.some(({ value }) => value === true)) {
                setGlobalFilter({ ...globalFilter, alerts: { ...globalFilter.alerts, outcome: { type: "in", values: AlertOutcome } } });
              } else {
                setGlobalFilter({ ...globalFilter, alerts: { ...globalFilter.alerts, outcome: { type: "in", values: e } } });
              }
            }} />

          {/* Status */}
          <MultiSelection
            title={intl.formatMessage({ id: 'ALERTS.STATUS' })}
            globalFilterValue={globalFilter?.['alerts']?.status?.values}
            options={AlertStatus}
            hideOptions={
              AlertStatus.filter((status) => status.value === 'PROCESSING' || status.value === 'ERROR')
            }
            onChange={(e) => {
              if (e.some(({ value }) => value === true)) {
                setGlobalFilter({ ...globalFilter, alerts: { ...globalFilter.alerts, status: { type: "in", values: ["PENDING", "COMPLETED", "ERROR", "PROCESSING", "FAILED"] } } });
              } else {
                const values = []
                e.forEach((option) => {
                  values.push(option)
                  if (option.value === 'PENDING') {
                    values.push(AlertStatus.find((e) => e.value === 'PROCESSING'))
                  } else if (option.value === 'FAILED') {
                    values.push(AlertStatus.find((e) => e.value === 'ERROR'))
                  }
                })
                setGlobalFilter({ ...globalFilter, alerts: { ...globalFilter.alerts, status: { type: "in", values: values } } });
              }
            }} />

          {/* Refunded */}
          <SingleSelection
            title={intl.formatMessage({ id: 'ALERTS.REFUNDED' })}
            globalFilterValue={globalFilter?.['alerts']?.['refund_result.status']?.values.length > 0 ? globalFilter?.['alerts']?.['refund_result.status']?.values : globalFilter?.['alerts']?.refunded?.values}
            options={[
              { value: "SUCCESS", label: intl.formatMessage({ id: 'ALERTS.REFUNDED_OPTIONS.SUCCESS' }) }, // auto refund success
              { value: "refunded", label: intl.formatMessage({ id: 'ALERTS.REFUNDED_OPTIONS.REFUNDED' }) },
              { value: "not refunded", label: intl.formatMessage({ id: 'ALERTS.REFUNDED_OPTIONS.NOT_REFUNDED' }) },
              { value: "not settled", label: intl.formatMessage({ id: 'ALERTS.REFUNDED_OPTIONS.NOT_SETTLED' }) },
            ]}
            isClearable
            onChange={(e) => {
              if (e?.value === 'SUCCESS') {
                setGlobalFilter({ ...globalFilter, alerts: { ...globalFilter.alerts, 'refund_result.status': { type: "in", values: [e] }, refunded: { type: "in", values: [] } } });
              } else if (e?.value === "refunded" || e?.value === "not refunded" || e?.value === "not settled") {
                setGlobalFilter({ ...globalFilter, alerts: { ...globalFilter.alerts, 'refund_result.status': { type: "in", values: [] }, refunded: { type: "in", values: [e] } } });
              } else {
                setGlobalFilter({ ...globalFilter, alerts: { ...globalFilter.alerts, 'refund_result.status': { type: "in", values: [] }, refunded: { type: "in", values: [] } } });
              }
            }} />

          {/* Initiated By */}
          <MultiSelection
            title={intl.formatMessage({ id: 'ALERTS.INITIATED_BY' })}
            globalFilterValue={globalFilter?.['alerts']?.['alert.InitiatedBy']?.values}
            options={AlertInitiatedBy}
            onChange={(e) => {
              setGlobalFilter({ ...globalFilter, alerts: { ...globalFilter.alerts, 'alert.InitiatedBy': { type: "in", values: e } } });
            }} />
        </div>

        {/* Search */}
        <SearchInputGroup
          inputs={[ // key = filter key
            { key: 'alert.EthocaID', title: intl.formatMessage({ id: 'ALERTS.ETHOCA_ID' }), minWidth:230 },
            { key: 'alert.CardNumber', title: intl.formatMessage({ id: 'ALERTS.CARD_NUMBER' }), minWidth:230 },
            { key: 'alert.MerchantDescriptor', title: intl.formatMessage({ id: 'ALERTS.MERCHANT_DESCRIPTOR' }) },
            { key: 'merchant_id', title: intl.formatMessage({ id: 'ALERTS.CLIENT_ID' }) },
            { key: 'alert.ARN', title: intl.formatMessage({ id: 'ALERTS.ARN' }) }
          ]}
          section='alerts' // globalFilter section key
          globalFilterValue={globalFilter}
          onSubmit={(searchValues) => setGlobalFilter({ ...globalFilter, alerts: { ...globalFilter.alerts, ...searchValues } })}
          onClear={(searchValues) => setGlobalFilter({ ...globalFilter, alerts: { ...globalFilter.alerts, ...searchValues } })}
        />
      </div>
    </>
  )
}
