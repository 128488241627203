import { Toast } from "react-bootstrap";
import ToastContainer from 'react-bootstrap/ToastContainer';

export type ToastStyle = 'Primary' | 'Secondary' | 'Success' | 'Danger' | 'Warning' | 'Info' | 'Light' | 'Dark' | ''

export type Props = {
    toast: { show: boolean, type: ToastStyle, header: string, message: string },
    setToast: (v: { show: boolean, type: ToastStyle, header: string, message: string }) => void,
}

export default function ToastMessage({ toast, setToast }: Props) {
    return (
        <>
            <ToastContainer className="p-3 top-5" position={'top-end'} containerPosition={'fixed'}>
                <Toast
                    className="d-inline-block m-1"
                    bg={toast.type.toLowerCase()}
                    onClose={() => setToast({ ...toast, show: false })}
                    show={toast.show}
                    delay={3000}
                    autohide
                >
                    <Toast.Header>
                        <strong className="me-auto">{toast.header}</strong>
                    </Toast.Header>
                    <Toast.Body className='text-white'>
                        {toast.message}
                    </Toast.Body>
                </Toast>
            </ToastContainer>
        </>
    )
}