/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { useAuth } from '../../../../app/modules/auth'
import { Languages } from './Languages'
import { ChangePassword } from './ChangePassword'
import { toAbsoluteUrl } from '../../../helpers'
import { useFilter } from '../../../../app/modules/components/filter/FilterContext'
import { useIntl } from 'react-intl'
import { useQuery } from 'react-query'
import { api } from '../../../../app/modules/api/api'
import { useToast } from '../../../../app/modules/components/toast/ToastContext'
import PermissionChecker from '../../../../app/modules/components/permission/PermissionChecker'

const HeaderUserMenu: FC = () => {
  const {
    auth,
    currentUser,
    refresh,
    logout
  } = useAuth()

  const intl = useIntl()
  const { setToast } = useToast();

  // User Permission
  const userPermission = useQuery(['permission'], async () => {
    try {
      const { result, status } = await api(`auth/tokens/current/access`, {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
        },
      }, { token: auth?.accessToken, refresh }, 'cbp-service').then((resp) => { return { "result": resp?.json(), "status": resp?.status } });

      if (status === 200) return result;
    } catch (err: any) {
      let response = await err?.response?.json();
      setToast({ show: true, type: 'Danger', header: intl.formatMessage({ id: 'MESSAGE.PERMISSION_ERROR' }), message: response?.message ?? err.message });
    }
  }, {})

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-auto min-w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img alt='Logo' src={toAbsoluteUrl('/media/avatars/blank.png')} />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {currentUser?.display_name}
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {currentUser?.email}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <Languages />

      <ChangePassword />

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          {intl.formatMessage({ id: 'MENU.SIGN_OUT' })}
        </a>
      </div>
    </div>
  )
}

export { HeaderUserMenu }
