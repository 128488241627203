/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { useLayout } from '../../core'
import { AsideMenu } from './AsideMenu'
import { useAuth } from '../../../../app/modules/auth'
import { useIntl } from 'react-intl'

const AsideDefault: FC = () => {
  const { config, classes } = useLayout()
  const { aside } = config
  const intl = useIntl()
  const styles = {
    logo_style: {
      marginLeft: '5px',
    },
  }

  // Icon will be navigate to alert list if no permission
  // const { currentUser, logout } = useAuth();
  // const statisticPermission = currentUser?.permissions?.find((permissions) => permissions?.service === 'STATISTICS');
  // const haveDashBoardPermission = statisticPermission?.access?.some((access) => access === 'dashboard.read') ?? false;

  return (
    <div
      id='kt_aside'
      className={clsx('aside', classes.aside.join(' '))}
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_aside_mobile_toggle'
    >
      {/* begin::Brand */}
      <div className='aside-logo flex-column-auto' id='kt_aside_logo'>
        {/* begin::Logo */}
        {aside.theme === 'dark' && (
          // <Link to={haveDashBoardPermission ? '/dashboard' : '/ethoca/alerts/list'}>
          <Link to='/ethoca/alerts/list'>
            <img
              alt='Logo'
              className='h-20px w-auto logo mt-6'
              style={styles.logo_style}
              src={toAbsoluteUrl('/media/logos/logo-w.png')}
            />
          </Link>
        )}
        {aside.theme === 'light' && (
          // <Link to={haveDashBoardPermission ? '/dashboard' : '/ethoca/alerts/list'}>
          <Link to='/ethoca/alerts/list'>
            <img alt='Logo' className='h-20px w-auto logo mt-6 mx-2' src={toAbsoluteUrl('/media/logos/logo.png')} />
          </Link>
        )}
        {/* end::Logo */}

        {/* begin::Aside toggler */}
        {aside.minimize && (
          <div
            id='kt_aside_toggle'
            className='btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle'
            data-kt-toggle='true'
            data-kt-toggle-state='active'
            data-kt-toggle-target='body'
            data-kt-toggle-name='aside-minimize'
          >
            {/* <i className="bi bi-search"></i> */}
            <KTSVG
              path={'/media/icons/duotune/arrows/arr080.svg'}
              className={'svg-icon-1 rotate-180 mt-6'}
            />
          </div>
        )}
        {/* end::Aside toggler */}
      </div>
      {/* end::Brand */}

      {/* begin::Aside menu */}
      <div className='aside-menu flex-column-fluid'>
        <AsideMenu asideMenuCSSClasses={classes.asideMenu} />
      </div>

      <div className='aside-footer flex-column-auto pt-5 pb-7 px-5' id='kt_aside_footer'>
        <div className='text-center px-10 pb-3' id=''>
          <span className='text-dark'>{intl.formatMessage({ id: 'COMMON.TIME_ZONE_UTC_8' })}</span>
        </div>
        {/* <a
          target='_blank'
          className='btn btn-custom btn-primary w-100'
          onClick={logout}
          data-bs-toggle='tooltip'
          data-bs-trigger='hover'
          data-bs-dismiss-='click'
        >
          <span className='btn-label'>Sign Out</span>
        </a> */}
      </div>
      {/* end::Aside menu */}
    </div>
  )
}

export { AsideDefault }
