/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../../helpers'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { changePassword, getPasswordChangeToken } from '../../../../app/modules/auth/core/_requests'
import clsx from 'clsx'
import { useToast } from '../../../../app/modules/components/toast/ToastContext'
import { useIntl } from 'react-intl'



const initialValues = {
    currentPassword: '',
    password: '',
    cPassword: '',
}

const ChangePassword: FC = () => {
    const [show, setShow] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)

    const intl = useIntl();
    const { setToast } = useToast();

    const changePasswordSchema = Yup.object().shape({
        currentPassword: Yup.string()
            .required(intl.formatMessage({ id: 'VALIDATE_ERROR.CHANGE_PASSWORD.CURRENT_PASSWORD_REQUIRED' })),
        password: Yup.string()
            .notOneOf([Yup.ref('currentPassword')], intl.formatMessage({ id: 'VALIDATE_ERROR.CHANGE_PASSWORD.PASSWORD_NO_DIFF' }))
            .min(8, intl.formatMessage({ id: 'VALIDATE_ERROR.CHANGE_PASSWORD.MIN_8_CHAR' }))
            .max(50, intl.formatMessage({ id: 'VALIDATE_ERROR.CHANGE_PASSWORD.MAX_50_CHAR' }))
            .matches(/^(?=.*[a-zA-Z])(?=.*\d)[^\s]{8,50}$/, { message: intl.formatMessage({ id: 'VALIDATE_ERROR.CHANGE_PASSWORD.PASSWORD_ALPHANUMERIC' }) })
            .required(intl.formatMessage({ id: 'VALIDATE_ERROR.CHANGE_PASSWORD.NEW_PASSWORD_REQUIRED' })),
        cPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], intl.formatMessage({ id: 'VALIDATE_ERROR.CHANGE_PASSWORD.NOT_MATCH' }))
            .required(intl.formatMessage({ id: 'VALIDATE_ERROR.CHANGE_PASSWORD.CONFIRM_PASSWORD_REQUIRED' })),
    })

    const formik = useFormik({
        initialValues,
        validationSchema: changePasswordSchema,
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            setLoading(true)
            try {
                const { data: passwordToken } = await getPasswordChangeToken(values.currentPassword);
                const { status } = await changePassword(values.password, passwordToken?.results);
                if (status === 200) {
                    setLoading(false);
                    setToast({ show: true, type: 'Success', header: intl.formatMessage({ id: 'MESSAGE.SUCCESSFUL' }), message: intl.formatMessage({ id: 'MESSAGE.CHANGE_PASSWORD_SUCCESS' }) })
                    resetForm();
                    setShow(false);
                }
            } catch (error) {
                if (error.response.status === 401) {
                    setToast({
                        show: true, type: 'Danger', header: intl.formatMessage({ id: 'MESSAGE.FAILED' }),
                        message: intl.formatMessage({ id: 'MESSAGE.SESSION_EXPIRED' })
                    })
                } else {
                    setToast({
                        show: true, type: 'Danger', header: intl.formatMessage({ id: 'MESSAGE.FAILED' }),
                        message: error?.response?.data?.code === 'auth/failed' ? intl.formatMessage({ id: 'MESSAGE.INVALID_CURRENT_PASSWORD' }) : error?.response?.data?.message
                    })
                }
                setSubmitting(false);
                setLoading(false);
            }
        },
    });

    return (
        <div
            className='menu-item px-5'
            data-kt-menu-trigger='hover'
            data-kt-menu-placement='left-start'
            data-kt-menu-flip='bottom'
        >
            <a onClick={() => setShow(true)} className='menu-link px-5'>
                <span className='menu-title position-relative'>
                    {intl.formatMessage({ id: 'TITLE.CHANGE_PASSWORD' })}
                </span>
            </a>

            {/* Change password modal */}
            <Modal
                id='kt_mega_menu_modal'
                aria-hidden='true'
                tabIndex={-1}
                dialogClassName='modal-dialog-centered mw-500px h-auto'
                show={show}
            >
                <div className='container-xxl px-10 py-10'>
                    <div className='modal-header py-0 d-flex border-0'>
                        {/* begin::Close */}
                        <div></div>
                        <div className=' mr-auto btn btn-icon btn-sm btn-light-primary' onClick={() => { setShow(false); formik.resetForm(); }}>
                            <KTSVG className='svg-icon-2' path='/media/icons/duotune/arrows/arr061.svg' />
                        </div>
                        {/* end::Close */}
                    </div>
                    <div className='modal-body'>
                        <div className='form'>
                            <form
                                className='form w-100'
                                onSubmit={formik.handleSubmit}
                                noValidate
                                id='kt_login_signin_form'
                            >
                                {/* begin::Heading */}
                                <div className='text-center mb-10'>
                                    <h1 className='text-dark mb-3'>{intl.formatMessage({ id: 'CHANGE_PASSWORD.CHANGE_PASSWORD' })}</h1>
                                </div>
                                {/* begin::Heading */}

                                {/* begin::Form group */}
                                <div className='fv-row mb-10'>
                                    <div className='d-flex justify-content-between mt-n5'>
                                        <div className='d-flex flex-stack mb-2'>
                                            {/* begin::Label */}
                                            <label className='form-label fw-bolder text-dark fs-6 mb-0'>
                                                {intl.formatMessage({ id: 'CHANGE_PASSWORD.CURRENT_PASSWORD' })}
                                            </label>
                                            {/* end::Label */}
                                        </div>
                                    </div>
                                    <input
                                        type='password'
                                        autoComplete='off'
                                        {...formik.getFieldProps('currentPassword')}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                        )}
                                    />
                                </div>
                                {/* end::Form group */}

                                {/* begin::Form group */}
                                <div className='fv-row mb-10'>
                                    <div className='d-flex justify-content-between mt-n5'>
                                        <div className='d-flex flex-stack mb-2'>
                                            {/* begin::Label */}
                                            <label className='form-label fw-bolder text-dark fs-6 mb-0'>{intl.formatMessage({ id: 'CHANGE_PASSWORD.NEW_PASSWORD' })}</label>
                                            {/* end::Label */}
                                        </div>
                                    </div>
                                    <input
                                        type='password'
                                        autoComplete='off'
                                        {...formik.getFieldProps('password')}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                        )}
                                    />
                                </div>
                                {/* end::Form group */}

                                {/* begin::Form group */}
                                <div className='fv-row mb-10'>
                                    <div className='d-flex justify-content-between mt-n5'>
                                        <div className='d-flex flex-stack mb-2'>
                                            {/* begin::Label */}
                                            <label className='form-label fw-bolder text-dark fs-6 mb-0'>{intl.formatMessage({ id: 'CHANGE_PASSWORD.CONFIRM_PASSWORD' })}</label>
                                            {/* end::Label */}
                                        </div>
                                    </div>
                                    <input
                                        type='password'
                                        autoComplete='off'
                                        {...formik.getFieldProps('cPassword')}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                        )}
                                    />
                                    {/* Error Message */}
                                    {formik.touched.currentPassword && formik.errors.currentPassword && (
                                        <div className='fv-plugins-message-container text-danger'>
                                            <span role='alert'>{formik.errors.currentPassword}</span>
                                        </div>
                                    )}
                                    {formik.touched.password && formik.errors.password && (
                                        <div className='fv-plugins-message-container text-danger'>
                                            <span role='alert'>{formik.errors.password}</span>
                                        </div>
                                    )}
                                    {formik.touched.cPassword && formik.errors.cPassword && (
                                        <div className='fv-plugins-message-container text-danger'>
                                            <span role='alert'>{formik.errors.cPassword}</span>
                                        </div>
                                    )}
                                    {formik.status && (
                                        <div className='fv-plugins-message-container text-danger'>
                                            <span role='alert'>{formik.status}</span>
                                        </div>
                                    )}
                                </div>
                                {/* end::Form group */}

                                {/* begin::Action */}
                                <div className='text-center'>
                                    <button
                                        type='submit'
                                        id='kt_sign_in_submit'
                                        className='btn btn-lg btn-primary w-100 mb-5'
                                        disabled={formik.isSubmitting || !formik.isValid}
                                    >
                                        {!loading && <span className='indicator-label'>{intl.formatMessage({ id: 'COMMON.CONTINUE' })}</span>}
                                        {loading && (
                                            <span className='indicator-progress' style={{ display: 'block' }}>
                                                {intl.formatMessage({ id: 'COMMON.PLEASE_WAIT' })}
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        )}
                                    </button>
                                </div>
                                {/* end::Action */}
                            </form>
                        </div>
                    </div>

                </div>
            </Modal>

        </div>
    )
}

export { ChangePassword }
