import React, { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { requestPasswordReset } from '../core/_requests'
import Captcha from './Captcha'
import { useIntl } from 'react-intl'

const initialValues = {
  email: '',
}

export function ForgotPassword() {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [isCaptchaVerified, setIsCaptchaVerified] = useState<boolean>(false);
  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({ id: 'VALIDATE_ERROR.INVALID_EMAIL_FORMAT' }))
      .min(3, intl.formatMessage({ id: 'VALIDATE_ERROR.MIN_3_SYMBOLS' }))
      .max(50, intl.formatMessage({ id: 'VALIDATE_ERROR.MAX_50_SYMBOLS' }))
      .required(intl.formatMessage({ id: 'VALIDATE_ERROR.EMAIL_REQUIRED' })),
  })
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      if (isCaptchaVerified) {
        setLoading(true)
        setHasErrors(undefined)
        requestPasswordReset(values.email) // call api
          .then(({ status }) => {
            if (status === 200) {
              setHasErrors(false)
              setLoading(false)
              setStatus('')
            }
          })
          .catch(() => {
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
          })
      } else {
        setStatus(intl.formatMessage({ id: 'MESSAGE.INVALID_CAPTCHA' }))
      }
    },
  })

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3'>{intl.formatMessage({ id: 'TITLE.FORGOT_PASSWORD' })}</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-400 fw-bold fs-4'>{intl.formatMessage({ id: 'FORGOT_PASSWORD.DESCRIPTION' })}</div>
          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              {intl.formatMessage({ id: 'FORGOT_PASSWORD.FAIL_TEXT' })}
            </div>
          </div>
        )}

        {hasErrors === false && (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>{intl.formatMessage({ id: 'FORGOT_PASSWORD.SUBMITTED_TEXT' })}</div>
          </div>
        )}
        {/* end::Title */}

        {/* begin::Form group */}
        <div className='fv-row mb-5'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>{intl.formatMessage({ id: 'FORGOT_PASSWORD.EMAIL' })}</label>
          <input
            type='email'
            placeholder=''
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              { 'is-invalid': formik.touched.email && formik.errors.email },
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container text-danger'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}

          {/* Captcha */}
          <div className='pt-5'>
            <Captcha onChange={(value) => { setIsCaptchaVerified(value) }} />
          </div>
          {formik.status && (
            <div className='fv-plugins-message-container text-danger'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.status}</span>
              </div>
            </div>
          )}

        </div>

        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button
            type='submit'
            id='kt_password_reset_submit'
            className='btn btn-lg btn-primary fw-bolder me-4'
            disabled={loading || hasErrors === false}
          >
            {!loading && <span className='indicator-label'>{intl.formatMessage({ id: 'COMMON.SUBMIT' })}</span>}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                {intl.formatMessage({ id: 'COMMON.PLEASE_WAIT' })}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-lg btn-light-primary fw-bolder'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {intl.formatMessage({ id: 'COMMON.CANCEL' })}
            </button>
          </Link>{' '}
        </div>
        {/* end::Form group */}
      </form>
    </>
  )
}
