import { useFilter } from '../../FilterContext';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import clsx from 'clsx';
import MultiSelection from '../../components/MultiSelection';
import SearchInputGroup from '../../components/SearchInputGroup';
import { useIntl } from 'react-intl';

type dataProps = {
  render?: any,
};
interface ClientType {
  readonly value: string;
  readonly label: string;
}
interface ClientRole {
  readonly value: string;
  readonly label: string;
}
interface ClientStatus {
  readonly value: string;
  readonly label: string;
}

export function ListFilter() {
  const params = useParams();

  const { globalFilter, setGlobalFilter } = useFilter();
  const intl = useIntl()

  const ClientType: ClientType[] = [
    { value: 'SUB_MERCHANT', label: intl.formatMessage({ id: 'CLIENTS.TYPE_OPTIONS.SUB_MERCHANT' }) },
    { value: 'DIRECT_MERCHANT', label: intl.formatMessage({ id: 'CLIENTS.TYPE_OPTIONS.DIRECT_MERCHANT' }) },
  ];
  const ClientRole: ClientRole[] = [
    { value: 'MERCHANT', label: intl.formatMessage({ id: 'CLIENTS.ROLE_OPTIONS.MERCHANT' }) },
    { value: 'RESELLER', label: intl.formatMessage({ id: 'CLIENTS.ROLE_OPTIONS.RESELLER' }) },
    { value: 'ACQUIRER', label: intl.formatMessage({ id: 'CLIENTS.ROLE_OPTIONS.ACQUIRER' }) },
  ];

  const ClientStatus: ClientStatus[] = [
    { value: 'PROCESSING', label: intl.formatMessage({ id: 'CLIENTS.STATUS_OPTIONS.PROCESSING' }) },
    { value: 'PENDING', label: intl.formatMessage({ id: 'CLIENTS.STATUS_OPTIONS.PENDING' }) },
    { value: 'ACTIVE', label: intl.formatMessage({ id: 'CLIENTS.STATUS_OPTIONS.ACTIVE' }) },
    { value: 'DELETED', label: intl.formatMessage({ id: 'CLIENTS.STATUS_OPTIONS.DELETED' }) },
    { value: 'REJECTED', label: intl.formatMessage({ id: 'CLIENTS.STATUS_OPTIONS.REJECTED' }) },
  ];
  return (
    <>
      <div>
        <div className="d-flex">
          {/* Type */}
          <MultiSelection
            title={intl.formatMessage({ id: 'CLIENTS.TYPE' })}
            globalFilterValue={globalFilter?.['clientList']?.type?.values}
            options={ClientType}
            onChange={(e) => {
              setGlobalFilter({
                ...globalFilter,
                clientList: { ...globalFilter.clientList, type: { type: "in", values: e } },
              })
              sessionStorage.clear()
            }} />

          {/* Role */}
          <MultiSelection
            title={intl.formatMessage({ id: 'CLIENTS.ROLE' })}
            globalFilterValue={globalFilter?.['clientList']?.role?.values}
            options={ClientRole}
            onChange={(e) => {
              setGlobalFilter({
                ...globalFilter,
                clientList: { ...globalFilter.clientList, role: { type: "in", values: e } },
              })
              sessionStorage.clear()
            }} />

          {/* Status */}
          <MultiSelection
            title={intl.formatMessage({ id: 'CLIENTS.STATUS' })}
            globalFilterValue={globalFilter?.['clientList']?.status?.values}
            options={ClientStatus}
            hideOptions={
              ClientStatus.filter((status) => status.value === 'PROCESSING')
            }
            onChange={(e) => {
              const values = []
              e.forEach((option) => {
                values.push(option)
                if (option.value === 'PENDING') {
                  values.push(ClientStatus.find((e) => e.value === 'PROCESSING'))
                }
              })
              setGlobalFilter({
                ...globalFilter,
                clientList: { ...globalFilter.clientList, status: { type: "in", values: values } },
              })
              sessionStorage.clear()
            }} />

          {/* Search */}
          <SearchInputGroup
            inputs={[ // key = filter key
              { key: 'name', title: intl.formatMessage({ id: 'CLIENTS.CLIENT_NAME' }) },
            ]}
            section='clientList' // globalFilter section key
            globalFilterValue={globalFilter}
            onSubmit={(searchValues) => setGlobalFilter({ ...globalFilter, clientList: { ...globalFilter.clientList, ...searchValues } })}
            onClear={(searchValues) => setGlobalFilter({ ...globalFilter, clientList: { ...globalFilter.clientList, ...searchValues } })}
          />
        </div >
      </div >
    </>
  )
}
