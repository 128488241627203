import React, { useContext, useState } from 'react'

const FilterContext = React.createContext<{
  globalFilter: Record<string, any>
  setGlobalFilter: React.Dispatch<React.SetStateAction<Record<string, any>>>
}>({
  globalFilter: undefined,
  setGlobalFilter: function (value: React.SetStateAction<Record<string, any>>): void { },
})

// export FilterContext value
export function useFilter() {
  return useContext(FilterContext)
}

export function FilterProvider({ children }) {
  const [globalFilter, setGlobalFilter] = useState<Record<string, any>>({})

  return (
    <FilterContext.Provider value={{ globalFilter, setGlobalFilter }}>
      {children}
    </FilterContext.Provider>
  )
}
